import {
  IonModal,
  IonButton,
  IonContent,
  IonSpinner,
  IonGrid,
  IonIcon,
  IonRow,
  IonCol,
  IonCheckbox,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import {
  editAnniversaryModalState,
  selectedAnniversaryState,
  anniversaryDataState,
  anniversaryWalletsState,
} from './atoms';
import { closeCircleOutline } from 'ionicons/icons';
import AnniversaryService from '../../Shared/services/AnniversaryService';
import moment from 'moment';
import UserTargetSelector from '../../components/UserTargetSelector';
import MemberIdSelector from '../../components/MemberIdSelector';
import DateSelector from '../../components/DateSelector';

function EditAnniversaryModal() {
  const [anniversary, setAnniversary] = useRecoilState(selectedAnniversaryState);
  const [, setAnniversaries] = useRecoilState(anniversaryDataState);
  const [isOpen, setIsOpen] = useRecoilState(editAnniversaryModalState);
  const [, setLoading] = useState(false);
  const [wallets] = useRecoilState(anniversaryWalletsState);

  const handleSave = async () => {
    if (anniversary == null) return;
    setLoading(true);
    await AnniversaryService.UpdateAnniversary(anniversary).then((resp) =>
      setAnniversaries((prev) => {
        return prev.map((p) => (p.id === anniversary.id ? resp.data : p));
      }),
    );
    setIsOpen(false);
    setLoading(false);
  };

  const cancel = () => {
    setLoading(true);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <IonModal className='mx-modal' isOpen={isOpen} backdropDismiss={true} onDidDismiss={cancel}>
      <IonContent className='ion-padding'>
        <div style={{ padding: 22, marginTop: -40 }}>
          {anniversary ? (
            <>
              <div
                style={{
                  display: 'flex',
                  fontSize: 36,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: 22,
                  paddingRight: 22,
                }}
              >
                <h6>Edit your existing anniversary</h6>
                <IonIcon
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={cancel}
                  icon={closeCircleOutline}
                />
              </div>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  {anniversary.isBirthdayReward !== true && /** Schedule isn't real if it's a bithday reward, so just hide it */
                    <IonCol>
                      <div>
                        Schedule
                        <input
                          className='mx-border'
                          readOnly={true}
                          placeholder={anniversary.schedule ?? 'Create Anniversary Schedule'}
                          value={anniversary.schedule ?? '0-days'}
                          onChange={(e) =>
                            setAnniversary((a) => {
                              if (a === null) return null;
                              return { ...a, schedule: e.target.value };
                            })
                          }
                        />
                      </div>
                    </IonCol>
                  }
                  <IonCol>
                    <div>
                      Amount
                      <input
                        className='mx-border'
                        type='number'
                        placeholder='$0.00'
                        value={anniversary.amount}
                        onChange={(e) =>
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return { ...a, amount: e.target.valueAsNumber };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div>
                      Message
                      <input
                        title='Message can be variablized with the following fields: [member.FirstName], [member.LastName], [employer.Name], [anniversary.Schedule]'
                        className='mx-border'
                        type='text'
                        value={anniversary.message ?? undefined}
                        onChange={(e) =>
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return { ...a, message: e.target.value };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                  <div>
                    From Wallet
                    <div>
                      <select
                        className='mx-border'
                        value={anniversary?.fromWalletId || 0}
                        onChange={(e) => {
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return {
                              ...a,
                              fromWalletId: parseInt(e.target.value),
                            };
                          });
                        }}
                        style={{
                          padding: 8,
                          backgroundColor: '#fcfcfc !important',
                          width: '100%',
                          marginTop: 10,
                          boxShadow: '2px 2px 4px -2px rgba(0,0,0,0.75)',
                        }}
                      >
                        <option value={0} disabled>
                          Select wallet...
                        </option>
                        {wallets.map((wallet) => (
                          <option key={wallet.id} value={wallet.id}>
                            {wallet.name} - ${(wallet.balance / 100).toFixed(2)}{' '}
                            {wallet.isPrimary && '(Central Admin Wallet)'}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div>
                      Anniversary Run Start Date
                      <DateSelector
                        id='startDate'
                        value={anniversary.startDate}
                        onChange={(d) => 
                          setAnniversary((a) => {
                            if(a === null) return null;
                            return {
                              ...a,
                              startDate: d ?? new Date()
                            };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div>
                      Anniversary Run End Date
                      <DateSelector
                        id='endDate'
                        value={anniversary.endDate}
                        onChange={(d) => 
                          setAnniversary((a) => {
                            if(a === null) return null;
                            return {
                              ...a,
                              endDate: d
                            };
                          })
                        }
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div>
                      Anniversary Sent By
                      <MemberIdSelector
                        onMemberIdChange={(e) => {
                          setAnniversary((a) => {
                            if (a === null) return null;
                            return {
                              ...a,
                              sentByMemberId: parseInt(e.target.value ?? '0'),
                            };
                          });
                        }}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                {anniversary.isBirthdayReward !== true && /** UserTargets aren't real if it's a bithday reward, so just hide it */
                  <IonRow>
                    <UserTargetSelector
                      onTargetTypeChange={(e) => {
                        setAnniversary((a) => {
                          if (a === null) return null;
                          return {
                            ...a,
                            targetType: parseInt(e.target.value ?? '0'),
                          };
                        });
                      }}
                      onTargetIdChange={(e) => {
                        setAnniversary((a) => {
                          if (a === null) return null;
                          return { ...a, targetIds: e.target.value.toString() };
                        });
                      }}
                      initialSelectedIds={anniversary.targetIds}
                      initialTargetType={anniversary?.targetType?.toString()}
                    />
                  </IonRow>
                } 
                <IonRow>
                  <div>
                    <IonCheckbox
                      justify='start'
                      labelPlacement="end"
                      checked={anniversary.isBirthdayReward}
                      onIonChange={(e) => {
                        setAnniversary((a) => {
                          if(a === null) return null;
                          return {
                            ...a,
                            isBirthdayReward: e.detail.checked
                          };
                        })
                      }}>
                      
                    </IonCheckbox>
                    Enable Birthday Reward using this Anniversary
                  </div>
                </IonRow>
                <IonRow>
                  <IonButton color='dark' onClick={handleSave}>
                    Update Anniversary
                  </IonButton>
                </IonRow>
                <IonRow>
                  <IonButton color='light' onClick={cancel}>
                    Cancel
                  </IonButton>
                </IonRow>
              </IonGrid>
            </>
          ) : (
            <div style={{ textAlign: 'center', paddingTop: 22 }}>
              <IonSpinner name='lines' />
            </div>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
}

export default EditAnniversaryModal;
