import React, { useEffect } from 'react';
import {
  anniversaryDataState,
  createAnniversaryModalState,
  selectedAnniversaryState,
} from './atoms';
import pluralize from 'pluralize';
import { useRecoilState } from 'recoil';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { add, search } from 'ionicons/icons';
import useSearch from '../../Shared/hooks/useSearch';
import AnniversaryService, { Anniversary } from '../../Shared/services/AnniversaryService';
import { AxiosResponse } from 'axios';

function AnniversarySidebar() {
  const [selectedAnniversary, setSelectedAnniversary] = useRecoilState(selectedAnniversaryState);
  const [, setIsOpen] = useRecoilState(createAnniversaryModalState);
  const [anniversaries, setAnniversaries] = useRecoilState(anniversaryDataState);
  const { results, searchValue, setSearchValue } = useSearch({
    dataSet: anniversaries,
    keys: ['schedule', 'amount', 'message', 'targetTypeName'],
  });
  const [loadingAnniversaries, setLoadingAnniversaries] = React.useState(false);
  const [, setInitialAnniversary] = React.useState(true);

  useEffect(() => {
    setLoadingAnniversaries(true);
    AnniversaryService.GetAnniversaries()
      .then((anniversaryResp: AxiosResponse<Anniversary[]>) => {
        const anniversaryData = anniversaryResp.data;
        setAnniversaries(anniversaryData);
        if (anniversaryData.length > 0) {
          setSelectedAnniversary(anniversaryData[0]);
          setInitialAnniversary(false);
        }
      })
      .finally(() => setLoadingAnniversaries(false));
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh)',
        width: 280,
        borderRight: '3px solid black',
      }}
    >
      <div
        style={{
          padding: 12,
        }}
      >
        <h6
          style={{
            marginTop: 140,
          }}
        >
          Current Anniversaries
        </h6>
        <div
          style={{
            position: 'relative',
          }}
        >
          <IonIcon
            color='primary'
            icon={search}
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          />
          <input
            style={{
              width: 240,
              backgroundColor: '#FFF',
              border: '1px solid #EBEBEB',
              padding: 6,
              borderRadius: 10,
              marginTop: 5,
              paddingLeft: 32,
              paddingTop: 2,
              paddingBottom: 2,
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value?.toString() || '')}
          />
        </div>
      </div>
      <div
        style={{
          overflowY: 'auto',
        }}
      >
        {loadingAnniversaries && results.length === 0 && (
          <IonSpinner
            style={{
              marginTop: 24,
              marginLeft: 'calc(50% - 14px)',
            }}
          />
        )}
        {results.map((anniversary) => (
          <div
            key={anniversary.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 10,
              borderBottom: '1px solid lightgrey',
              cursor: 'pointer',
              backgroundColor:
                selectedAnniversary?.id == anniversary.id ? '#f7f7f7' : 'transparent',
            }}
            onClick={() => setSelectedAnniversary(anniversary)}
          >
            <div
              style={{
                width: '58%',
              }}
            >
            { anniversary.isBirthdayReward ?
            <h6
              style={{
                lineHeight: '20px',
                marginBottom: 6,
              }}
            >
              Users&apos; Birthday Reward
            </h6> 
            :
            <>
              <h6
                style={{
                  lineHeight: '20px',
                  marginBottom: 6,
                }}
              >
                {anniversary.schedule}
              </h6>
              <p
                style={{
                  color: 'grey',
                  fontSize: 10,
                  marginBottom: 0,
                }}
              >
                Targeting {anniversary.targetTypeName}
              </p>
            </>
            }
            </div>
            <div
              style={{
                width: '40%',
                textAlign: 'right',
              }}
            >
              <p
                style={{
                  lineHeight: '20px',
                  fontSize: 12,
                  marginBottom: 6,
                }}
              >
                ${anniversary.amount.toFixed(2)} Bonus
              </p>
              {anniversary.targetTypeName !== 'AllUsers' ? (
                <p
                  style={{
                    fontSize: 10,
                    color: 'grey',
                    marginBottom: 0,
                  }}
                >
                  {anniversary.targetIds?.split(',').length}{' '}
                  {pluralize(anniversary.targetTypeName, anniversary.targetIds?.split(',').length)}
                </p>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          marginTop: 12,
          textAlign: 'center',
        }}
      >
        <IonButton
          fill='clear'
          size='small'
          onClick={() => {
            setIsOpen(true);
          }}
          style={{
            color: '#03989E',
            fontWeight: 400,
            textTransform: 'none',
          }}
        >
          <IonIcon
            icon={add}
            slot='start'
            size='small'
            style={{
              color: '#03989E',
              border: '1px solid #03989E',
              borderRadius: 4,
            }}
          />
          Create an Anniversary
        </IonButton>
      </div>
    </div>
  );
}

export default AnniversarySidebar;
