import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  editAnniversaryModalState,
  selectedAnniversaryRunState,
  selectedAnniversaryState,
} from './atoms';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import AnniversaryService from '../../Shared/services/AnniversaryService';
import EditAnniversaryModal from './EditAnniversaryModal';
import moment from 'moment';

function AnniversaryView() {
  const anniversary = useRecoilValue(selectedAnniversaryState);
  const [anniversaryRuns, setAnniversaryRuns] = useRecoilState(selectedAnniversaryRunState);
  const [, setShowEditModal] = useRecoilState(editAnniversaryModalState);
  const [status, setStatus] = useState<'active' | 'inactive'>();
  const [activeTab, setActiveTab] = useState<string>('Activity');

  useEffect(() => {
    if (!anniversary) return;

    AnniversaryService.GetAnniversaryRuns(anniversary.id).then((arData) => {
      setAnniversaryRuns(arData.data);
    });

    setStatus(anniversary?.isActive ? 'active' : 'inactive');
  }, [anniversary]);

  const handleStatusChange = (e: any) => {
    if (anniversary?.id) {
      const isActive = e.detail.value === 'active';
      AnniversaryService.SetAnniversaryActive(anniversary.id, isActive).then(() => {
        setStatus(isActive ? 'active' : 'inactive');
      });
    }
  };

  const tabs = [
    { name: 'Activity', value: 'Activity' },
    { name: 'Forecast', value: 'Forecast' },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <h3>Current Anniversary Rewards</h3>
          <p
            style={{
              fontSize: 12,
            }}
          >
            Created {moment(anniversary?.startDate).format('M/D/YYYY')}
          </p>
        </div>
        <IonButton
          fill='clear'
          size='small'
          style={{
            color: '#03989E',
            fontWeight: 400,
            textTransform: 'none',
          }}
          onClick={() => setShowEditModal(true)}
        >
          Edit Anniversary Reward
        </IonButton>
      </div>
      {anniversary == null ? (
        <h5>No Anniversary selected</h5>
      ) : (
        <>
          <div
            style={{
              marginTop: 20,
              padding: 20,
              background: 'linear-gradient(180deg, #D8EAE5 0%, #FFF 97%)',
              border: '1px solid lightgrey',
              borderRadius: 10,
              paddingBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                textAlign: 'center',
              }}
            >
              <div>
                <IonList className='ion-no-padding transparent'>
                  <IonItem
                    style={{
                      '--border-radius': '8px',
                      '--border-width': '0.5px',
                    }}
                  >
                    <IonSelect
                      value={status}
                      onIonChange={handleStatusChange}
                      aria-label='status'
                      placeholder='Select fruit'
                    >
                      <IonSelectOption value='active'>Active</IonSelectOption>
                      <IonSelectOption value='inactive'>Inactive</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonList>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Status
                </p>
              </div>
              <div>
                <h5>{anniversaryRuns.length}</h5>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Times Sent
                </p>
              </div>
              <div>
                <h5>${(0 / 100).toFixed(2)}</h5>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Amount Sent
                </p>
              </div>
            </div>
            <h4 style={{ fontSize: 16 }}>Anniversary Reward Details</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                textAlign: 'center',
              }}
            >
              <div>
                <h5>${(anniversary?.amount ?? 0).toFixed(2)}</h5>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Anniversary Reward amount
                </p>
              </div>
              { anniversary.isBirthdayReward ?
              <div>
                <h5>Users&apos; Birthday Reward</h5>
              </div> 
              :
              <>
                <div>
                  <h5>
                    {anniversary?.targetIds?.split(',').length} {anniversary?.targetTypeName}
                  </h5>
                  <p
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Eligible Anniversary Reward Recipients
                  </p>
                </div>
                <div>
                  <h5>At {anniversary?.schedule}</h5>
                  <p
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Scheduled Anniversary Rewards
                  </p>
                </div>
              </>
              }
              <div>
                <h5>${(0 / 100).toFixed(2)}</h5>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Funding Wallet
                </p>
              </div>
            </div>
          </div>
          <EditAnniversaryModal />
          <div
            style={{
              padding: '16px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
              {tabs.map((tab) => (
                <IonButton
                  key={tab.name}
                  size='small'
                  color={activeTab === tab.value ? 'dark' : 'light'}
                  onClick={() => setActiveTab(tab.value)}
                >
                  {tab.name}
                </IonButton>
              ))}
            </div>
            <div style={{ marginTop: '20px' }}>
              {activeTab === 'Activity' &&
                anniversaryRuns.map((aRun) => (
                  <IonCard
                    key={aRun.id}
                    style={{
                      marginBottom: '16px',
                      boxShadow: 'none',
                      border: '1px solid #e0e0e0',
                    }}
                  >
                    <IonCardHeader>
                      <IonCardTitle style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        {aRun.statusName}
                      </IonCardTitle>
                      <p
                        style={{
                          color: '#666',
                          fontSize: '14px',
                          margin: '4px 0',
                        }}
                      >
                        {moment(aRun.startedDateTime).format('M/D/YYYY hh:mm:ss')}
                      </p>
                    </IonCardHeader>
                    <IonCardContent>
                      <p style={{ fontSize: '16px', marginBottom: '8px' }}>
                        Targeted MemberIds: {aRun.memberIds}
                      </p>
                      {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#3880ff",
                          cursor: "pointer",
                        }}
                      >
                        <span style={{ marginRight: "4px" }}>
                          View Interactions
                        </span>
                        <IonIcon icon={chevronUp} />
                      </div> */}
                    </IonCardContent>
                  </IonCard>
                ))}
              {activeTab === 'Forecast' && null}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AnniversaryView;
